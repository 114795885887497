import React from 'react';
import Banner from './banner';

function App() {
  return <div>
    <Banner />
    <h1>Test Page</h1>
    <button>Click Me for Popup</button>
    <script>
      
    </script>
  </div>;
}

export default App;
