import React from 'react';

const Banner = () => {
    return (
        <header>
            <div>
                <img src="./logo192.png" alt="logo" />
            </div>
            <div>
                Providing REACT Experience
            </div>
        </header>
    );
};

export default Banner;